/* CheGuevaraBarry-Brown */
@font-face {
    font-family: 'CheGuevaraBarry';
    src: url('/fonts/CheGuevaraBarry-Brown.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* FiraSansCondensed-ExtraBoldItalic */
@font-face {
    font-family: 'FiraSansCondensed';
    src: url('/fonts/FiraSansCondensed-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

/* Gabarito-Black */
@font-face {
    font-family: 'Gabarito';
    src: url('/fonts/Gabarito-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

/* KOMIKAX_ */
@font-face {
    font-family: 'KOMIKAX';
    src: url('/fonts/KOMIKAX_.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Montserrat-Bold */
@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

/* Proxima-Nova-Semibold */
@font-face {
    font-family: 'Proxima Nova';
    src: url('/fonts/Proxima-Nova-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* Rubik-ExtraBold */
@font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

/* Font classes for use in the dropdown */
.font-che-guevara {
    font-family: 'CheGuevaraBarry', sans-serif;
}

.font-fira-sans {
    font-family: 'FiraSansCondensed', sans-serif;
    font-weight: 800;
    font-style: italic;
}

.font-gabarito {
    font-family: 'Gabarito', sans-serif;
    font-weight: 900;
}

.font-komikax {
    font-family: 'KOMIKAX', sans-serif;
}

.font-montserrat {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.font-proxima-nova {
    font-family: 'Proxima Nova', sans-serif;
    font-weight: 600;
}

.font-rubik {
    font-family: 'Rubik', sans-serif;
    font-weight: 800;
}